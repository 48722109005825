.branding-logo {

  padding: 10px 20px;
  /* background-color: floralwhite; */
}

.main-header {
  display: flex;
  background: floralwhite;
  justify-content: space-between;
}

.btn-primary {
  margin-right: 5px !important;
}