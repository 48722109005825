.heading-app-remove-flag h3 {
    border-bottom: 2px solid #dee2e6;
    padding: 15px 0px;
}

.heading-app-remove-flag {
    margin-bottom: 20px;
}

.app-remove-flag-container {
    background-color: #ffffff;
    padding: 10px 10px 30px 20px;
    border: 1px solid #f2f7f3;
    border-radius: 6px;
    margin-bottom: 20px;
}

.app-remove-flag-form .form-check {
    padding-right: 15px !important;
}