.addSetting-body {
  background-color: #e9e9e9 !important;
}

.mobile-preview-design {
  padding: 0px;
}

.app-color-container,
.native-container {
  background-color: #ffffff;
  padding: 10px 25px 30px 10px;
  border: 1px solid #f2f7f3;
  border-radius: 6px;
}

.main-title {
  font-size: 18px;
  font-weight: 700;
  color: black;
  line-height: 30px;
  padding-bottom: 4px;
  border-bottom: 1px solid #eaeaea;
}

.admin-app-color-section .title {
  font-size: 16px;
  font-weight: 600;
  color: black;
  line-height: 30px;
}

/* .container-color {
  border: 1px solid #eaeaea;
  position: relative;
  padding: 8px 8px 8px 0px;
  width: 50%;
} */

.colorpick-eyedropper-input-trigger {
  display: none;
}

/* #color-picker {
    opacity: -1;
    position: absolute;
    padding: 20px;
    width: 100%;
} */

.mobile-preview-design {
  max-width: 17rem;
  width: 100%;
  margin: 0 auto;
  border-radius: 20px;
  border: 5px solid #000000;
  max-height: 32rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* preview */
.preview-app-name-title {
  padding: 20px 16px 10px 16px;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  color: #ffffff;
}

.banner-image-section {
  max-width: 16.3rem;
  width: 100%;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
}

.Native-Color-container .container-color {
  width: 100%;
}

.Native-Color-container .col-2 {
  width: 21.666667%;
}

.nativeImage-div {
  max-width: 16.3rem;
  width: 100%;
}

.Test-Ad-title {
  font-size: 12px;
  font-weight: 800;
}

.Test-Ad-subtitle {
  font-size: 12px;
  font-weight: 400;
}

.native-btn {
  padding: 8px;
  border-radius: 6px;
  border: none;
}

.start-app-button {
  padding: 8px;
  border-radius: 6px;
  border: none;
  background-color: #5790db;
  color: white;
}

.start-app-div {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: calc(100% - 280px);
  height: 100%;
}

.start-app-btn-div {
  padding-bottom: 50px !important;
}

.admin-app-open .title {
  font-size: 16px;
  font-weight: 600;
  color: black;
  line-height: 30px;
}

.bifurcate-field-conatiner {
  background-color: white;
  padding: 10px 10px 30px 10px;
}

.container-color-box {
  justify-content: space-between;
}