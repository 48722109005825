.playstore-form .form-group {
    padding: 10px 0px;
}

.playstore-form .form-group .playstore-label {
    margin-bottom: 5px;
    font-weight: 500;
}

.heading-playstore {
    padding: 20px 0px;
}

.playstore-button-style {
    padding: 15px 0px;
}

.playstore-search .form-control {
    width: unset !important;
}