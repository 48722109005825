* {
    margin: 0;
    padding: 0;
    text-decoration: none;
}

/* .container{
    display:flex;
 
} */
main {
    width: 100%;
    padding: 20px;
}

.sidebar {
    background: white;
    color: black;
    height: 100%;
    /* width: 200px; */
    transition: all 0.5s;
}

.top_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 15px;
}

.logo {
    font-size: 30px;
}

.bars {
    display: flex;
    font-size: 25px;
    margin-left: 50px;
}

.link {
    display: flex;
    color: black;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
    text-decoration: none;
}

/* .link:hover{
    background:lightgray;
    color: #000;
    transition: all 0.5s;
} */
.sidebar .active {
    background: lightgrey;
    color: #000;
    text-decoration: none;
}

.icon,
.link_text {
    font-size: 20px;
}



/* edit user sidebar */
.editUser-sidebar .top_section {
    padding: 0px;
}

.editUser-sidebar .accordion-item {
    border: none;
}

.editUser-sidebar {
    height: 100vh;
}

.edit-user-navlink {
    position: absolute;
    width: 100%;
    text-decoration: none !important;
    color: black !important;
    font-size: 20px;
    font-weight: 400;
}

.edit-nav-menu {
    position: relative;
    width: 100%;

}

.edit-nav-menu .active {
    background-color: grey !important;
    padding: 4px;
    color: white !important;
    /* opacity: 0.5; */
    font-size: 16px
}

.accordion-item {
    background-color: ghostwhite !important;
}

.accordion-button:not(.collapsed) {
    background-color: ghostwhite !important;
}

.accordion-button {
    /* background-color: #ffffff !important; */
    font-weight: 600;
    font-size: 18px !important;
}

.accordion-button:focus {
    box-shadow: unset !important;
    border-color: none;
}

.user-menu a {
    font-weight: 600;
    font-size: 22px;
    text-decoration: none !important;
    color: black !important;
}