.vpn-organic-form .radio-btn-vpn {
    margin-right: 20px;
}

.vpn-heading h3 {
    padding: 15px 0px;
}

.vpn-heading {
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 20px;
}

.vpn-container {
    background-color: #ffffff;
    padding: 10px 10px 30px 20px;
    border: 1px solid #f2f7f3;
    border-radius: 6px;
    margin-bottom: 10px;

}