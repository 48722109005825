.heading-other-setting h3 {
    border-bottom: 2px solid #dee2e6;
    padding: 15px 0px;
}

.heading-other-setting {
    margin-bottom: 20px;
}

.other-container {
    background-color: white;
    padding: 0px 15px;
    margin-bottom: 20px;
}

.other-add-field {
    padding-left: 10px;
}