.google-setting-heading {
    margin-bottom: 6px;
    border-bottom: 2px solid #dee2e6;
}

.google-setting-heading h1 {
    padding: 15px 0px;

}

.google-setting-sub-heading h4 {
    padding: 10px 0px;
}

.google-o-conatiner {
    background-color: #ffffff;
    padding: 10px 10px 30px 20px;
    border: 1px solid #f2f7f3;
    border-radius: 6px;
    margin-bottom: 10px;
}