body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: ghostwhite !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#loginform{
  max-width: 500px;
  min-width: 300px;
  max-height: 300px;
  width: 50%;
  height: 40%;
  margin: 30px auto;
  background-color: #FFFFFF;
  border-radius: 25px;
}

.loginInput{
  box-sizing: border-box;
	border: none;
  font-size: 1.3rem;
  padding-left: 1.5rem;
  padding-bottom: 1rem;
  box-shadow: inset 0px -3px 0px 0px rgba(187,187,187,0.2);
  transition: box-shadow 0.2s ease-in;
}

.loginInput:focus{
   box-shadow: inset 0px -3px 0px 0px rgba(34,193,195,0.7);
   outline: none;
}

.loginInput::-webkit-input-placeholder{
  opacity: 1;
  transition: opacity 0.25s ease-out;
}

.loginInput:hover::-webkit-input-placeholder,
.loginInput:focus::-webkit-input-placeholder{
  opacity: 0;
}

.loginLabel{
  align-self: start;
  color: rgba(187,187,187,0.9);
}

.loginButton{
  margin-top: 20px;
  border-radius: 25px;
  font-size: 1.3rem;
  color: white;
  font-weight: 700;
  background: rgb(34,193,195);
  background: linear-gradient(90deg, rgba(34,193,195,1) 0%,     rgba(253,187,45,1) 100%);
  border: 0px;
  cursor: pointer;
  transition: opacity 0.25s ease-out;
}

.loginBody{
  background: rgb(34,193,195);
  background: linear-gradient(135deg, rgba(34,193,195,1) 0%,     rgba(253,187,45,1) 100%);
  font-family: "work sans";
}


