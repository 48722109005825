.packagename {
    /* text-decoration: none !important; */
    color: black;
    font-size: 14px;
    font-weight: 500;
}

.main-table-div a {
    text-decoration: none;
}

.consolename {
    color: green;
    font-size: 14px;
    font-weight: 500;
}

.appName {
    color: black;
    font-size: 18px;
    font-weight: 600;
}