.search-btn {
    padding: 0px 14px;
    background: blue;
    color: white;
    font-weight: 700;
    border: none;
    margin-left: 10px;
}

.nav-justified .nav-item .nav-link {

    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;
    border-radius: 0px;
}

.nav-link {
    color: black !important;
}

.nav-link:hover {
    color: black !important;
}

.nav-tabs .nav-link.active {
    font-weight: 500;
}

#controlled-tab-example-tab-develope {
    border-top: 4px solid rgb(125, 102, 243);

}

#controlled-tab-example-tab-upload {
    border-top: 4px solid sandybrown;

}

#controlled-tab-example-tab-live {
    border-top: 4px solid green;
}

#controlled-tab-example-tab-suspended {
    border-top: 4px solid red;
}

#controlled-tab-example-tab-ctro {
    border-top: 4px solid grey;
}

#controlled-tab-example-tab-add-apps {
    border-top: 4px solid rgb(11, 183, 231);
}

.add-app-select-tab .form-group {
    padding: 16px 0px;
}

.add-app-select-tab .form-control {
    margin-top: 5px;
}

.add-app-select-tab .form-select {
    margin-top: 5px;
}

.add-app-radio-btn-group .radio-btn-all {
    margin: 10px;
}

.nav-justified .nav-item {
    /* background-color: lightgray; */
}