.all-add-button .add-button {
    padding: 5px 14px;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 20px;
    font-size: 16px;
    text-align: center;
    text-transform: capitalize;

}

.add-button:disabled {
    /* background: #348ec6; */
    opacity: 40%;
}