.main-container .container {
    max-width: 1445px;
    width: 100%;
    margin: 0 auto;
}

.alert-massge {
    width: max-content;
    padding: 10px;
    margin: 10px auto 0 auto;
    /* transform: scaleX(2); */
}